import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { UserRole } from "../types";

interface ProtectedRouteProps {
  isAuthenticated: boolean;
  children: JSX.Element;
}

function ProtectedRoute({ isAuthenticated, children }: ProtectedRouteProps) {
  const navigate = useNavigate();
  const { role: userRole, fullName } = useAppSelector((state) => state.auth);
  if (userRole === UserRole.Employee) {
    navigate('/videogame');
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
}

export default ProtectedRoute;
